/** @jsxImportSource @emotion/react */
import {keyframes} from '@emotion/react'
import * as React from 'react'
import {NavLink, Container, NavContactButton} from 'utils/lib'
import logo from 'assets/logo.png'
import * as mq from 'utils/media-queries'
import * as color from 'utils/colors'
import {Link} from 'react-router-dom'

// Keyframe animations
const animateShowNav = keyframes({
	'0%': {
		height: '0',
	},
	'100%': {
		height: '17rem',
	},
})

const animateShowNavLink = keyframes({
	0: {
		opacity: '0',
	},
	'100%': {
		opacity: '100%',
	},
})

const animateHideNav = keyframes({
	'0%': {
		height: '17rem',
	},
	'100%': {
		height: '0',
	},
})

const animateHideNavLink = keyframes({
	0: {
		opacity: '100%',
	},
	'100%': {
		opacity: '0',
	},
})

// Navbar for smaller screens
function NavbarSmall({setShowNav}) {
	const [isHidden, setIsHidden] = React.useState(false)

	React.useEffect(() => {
		if (isHidden) {
			setTimeout(() => {
				setShowNav(false)
			}, 250)
		}
	}, [setShowNav, isHidden])

	return (
		<Container
			css={{
				backgroundColor: '#fff',
				position: 'absolute',
				zIndex: '9',
				width: '100%',
				height: '0px',
				fontFamily: 'TiemposHeadlineRegular, serif',
				transition: '1s ease-in-out',
				animation: isHidden
					? `${animateHideNav} 0.3s ease-in-out forwards`
					: `${animateShowNav} 0.3s ease-in-out forwards`,
				boxShadow: '1px 5px 6px 1px rgba(0,0,0,0.15)',
				textTransform: 'uppercase',
				[mq.extraSmall]: {
					display: 'block',
					marginTop: '100px',
				},
				[mq.small]: {
					display: 'block',
				},
				[mq.medium]: {
					display: 'none',
				},
				[mq.large]: {
					display: 'none',
				},
			}}
		>
			<div>
				<NavLink
					css={{
						fontFamily: 'TiemposHeadlineRegular, serif',
						fontWeight: '500',
						fontSize: '1.5rem',
						margin: '0.5rem auto',
						opacity: '0',
						display: 'inline-block',
						animation: isHidden
							? `${animateHideNavLink} 1s ease-in-out forwards`
							: `${animateShowNavLink} 0.3s 0.3s ease-in-out forwards`,
					}}
					to="/daycare"
					onClick={() => setIsHidden(true)}
				>
					Daycare
				</NavLink>
			</div>

			<div>
				<NavLink
					css={{
						fontFamily: 'TiemposHeadlineRegular, serif',
						fontWeight: '500',
						fontSize: '1.5rem',
						margin: '0.5rem auto',
						opacity: '0',
						display: 'inline-block',
						animation: isHidden
							? `${animateHideNavLink} 1s ease-in-out forwards`
							: `${animateShowNavLink} 0.3s 0.3s ease-in-out forwards`,
					}}
					to="/boarding"
					onClick={() => setIsHidden(true)}
				>
					Boarding
				</NavLink>
			</div>

			<div>
				<NavLink
					css={{
						fontFamily: 'TiemposHeadlineRegular, serif',
						fontWeight: '500',
						fontSize: '1.5rem',
						margin: '0.5rem auto',
						opacity: '0',
						display: 'inline-block',
						animation: isHidden
							? `${animateHideNavLink} 1s ease-in-out forwards`
							: `${animateShowNavLink} 0.3s 0.3s ease-in-out forwards`,
					}}
					to="/training"
					onClick={() => setIsHidden(true)}
				>
					Training
				</NavLink>
			</div>

			<div>
				<NavLink
					css={{
						fontFamily: 'TiemposHeadlineRegular, serif',
						fontWeight: '500',
						fontSize: '1.5rem',
						margin: '0.5rem auto',
						opacity: '0',
						display: 'inline-block',
						animation: isHidden
							? `${animateHideNavLink} 1s ease-in-out forwards`
							: `${animateShowNavLink} 0.3s 0.3s ease-in-out forwards`,
					}}
					to="/grooming"
					onClick={() => setIsHidden(true)}
				>
					Grooming
				</NavLink>
			</div>
			<div>
				<NavLink
					css={{
						fontFamily: 'TiemposHeadlineRegular, serif',
						fontWeight: '500',
						fontSize: '1.5rem',
						margin: '0.5rem auto',
						opacity: '0',
						display: 'inline-block',
						animation: isHidden
							? `${animateHideNavLink} 1s ease-in-out forwards`
							: `${animateShowNavLink} 0.3s 0.3s ease-in-out forwards`,
					}}
					to="/party-center"
					onClick={() => setIsHidden(true)}
				>
					Party Center
				</NavLink>
			</div>
			<div>
				<NavLink
					css={{
						fontFamily: 'TiemposHeadlineRegular, serif',
						fontWeight: '500',
						fontSize: '1.5rem',
						margin: '0.5rem auto',
						opacity: '0',
						display: 'inline-block',
						animation: isHidden
							? `${animateHideNavLink} 1s ease-in-out forwards`
							: `${animateShowNavLink} 0.3s 0.3s ease-in-out forwards`,
					}}
					to="/contact-us"
					onClick={() => setIsHidden(true)}
				>
					Contact Us
				</NavLink>
			</div>
		</Container>
	)
}

function Navbar() {
	const [showNav, setShowNav] = React.useState(false)
	const [onHover, setOnHover] = React.useState(false)

	return (
		<div
			css={{
				position: 'fixed',
				top: '0',
				zIndex: '9',
				backgroundColor: '#fff',
				width: '100%',
			}}
		>
			<div
				css={{
					height: '100px',
					position: 'relative',
					minWidth: '320px',
					display: 'flex',
					flexDirection: 'row',
					boxShadow: showNav
						? '1px 4px 6px 1px rgba(0,0,0,0.15)'
						: '1px 1px 6px 1px rgba(0,0,0,0.15)',
					[mq.extraSmall]: {
						justifyContent: 'space-between',
					},
					[mq.small]: {
						justifyContent: 'space-between',
					},
					[mq.medium]: {
						height: '100px',
					},
					[mq.large]: {
						height: '100px',
					},
				}}
			>
				{/* Small screen navigation icon */}
				<div
					css={{
						position: 'absolute',
						left: '0',
						userSelect: 'none',
						[mq.extraSmall]: {
							display: 'flex',
							height: '70px',
							marginTop: '1rem',
							justifyContent: 'center',
							alignItems: 'center',
						},
						[mq.small]: {
							display: 'flex',
							height: '70px',
							marginTop: '1rem',
							justifyContent: 'center',
							alignItems: 'center',
						},
						[mq.medium]: {
							display: 'none',
						},
						[mq.large]: {
							display: 'none',
						},
					}}
				>
					<div
						css={{
							fontSize: '2rem',
							margin: '1rem',
							cursor: 'pointer',
							userSelect: 'none',
						}}
						onClick={() => setShowNav(!showNav)}
					>
						<img
							css={{
								display: 'block',
							}}
							src={`https://global-uploads.webflow.com/5f99c7dfbbc17623931b16c2/5fa0ba59339d794592f4b7cd_burger-blk.svg`}
							alt="nav-icon"
						/>
					</div>
				</div>

				{showNav ? (
					<NavbarSmall showNav={showNav} setShowNav={setShowNav} />
				) : null}

				{/* Logo */}
				<div
					css={{
						margin: '1rem',
						width: '70px',
						minWidth: '70px',
						height: '70px',
						[mq.extraSmall]: {
							margin: '1rem auto',
						},
						[mq.small]: {
							margin: '1rem auto',
						},
						[mq.medium]: {
							width: '70px',
							minWidth: '70px',
							height: '70px',
						},
						[mq.large]: {
							width: '70px',
							height: '70px',
							margin: '1rem',
						},
					}}
				>
					<Link
						css={{
							width: '100%',
							height: '100%',
							display: 'inline-block',
						}}
						to="/"
						onClick={() => setShowNav(false)}
					>
						<img
							css={{
								width: '100%',
								height: '100%',
								userSelect: 'none',
							}}
							src={logo}
							alt="logo"
						/>
					</Link>
				</div>

				{/* Title and Navigation large screen */}
				<div
					css={{
						display: 'flex',
						flexDirection: 'column',
						flex: 1,
						[mq.extraSmall]: {
							flex: 'revert',
						},
						[mq.small]: {
							flex: 'revert',
						},
						[mq.medium]: {
							display: 'block',
						},
						[mq.large]: {
							display: 'block',
						},
					}}
				>
					<div
						css={{
							textAlign: 'left',
							marginTop: '1rem',
							flexDirection: 'row',
							[mq.extraSmall]: {
								display: 'none',
							},
							[mq.small]: {
								display: 'none',
							},
							[mq.medium]: {
								display: 'flex',
							},
							[mq.large]: {
								display: 'flex',
							},
						}}
					>
						<NavLink
							css={{
								fontFamily: 'Playfair Display, serif',
								textAlign: 'left',
								fontSize: '2.25rem',
							}}
							to="/"
						>
							Pampered Paws Salon and Spa
						</NavLink>

						{/* Contact Us Large Screen */}
						<div
							css={{
								display: 'flex',
								flexDirection: 'row',
								marginLeft: 'auto',
								[mq.extraSmall]: {
									display: 'none',
								},
								[mq.small]: {
									display: 'none',
								},
								[mq.medium]: {
									display: 'flex',
								},
								[mq.large]: {
									display: 'flex',
								},
							}}
						>
							<div
								css={{
									marginTop: '1rem',
								}}
							>
								<div>562-245-6537</div>
							</div>
							<div
								css={{
									marginTop: '1rem',
								}}
							>
								<a
									css={{
										padding: '0.75rem 1.5rem',
										marginLeft: '1rem',
										marginRight: '1rem',
										backgroundColor: onHover ? color.hover : color.button,
										transition: '0.3s ease-in-out',
										borderRadius: '40px',
										color: '#fff',
										textDecoration: 'none',
										fontFamily: 'Open Sans, san-serif',
										fontSize: '0.9rem',
										boxShadow: '0px 1px 5px 0px rgb(150, 150, 150, 1)',
									}}
									href={`mailto:${process.env.REACT_APP_EMAIL}`}
									onMouseOver={() => setOnHover(true)}
									onMouseOut={() => setOnHover(false)}
								>
									CONTACT US
								</a>
							</div>
						</div>
					</div>
					<div
						className="navbar-links"
						css={{
							textAlign: 'left',
							fontSize: '1.15rem',
							[mq.extraSmall]: {
								display: 'none',
							},
							[mq.small]: {
								display: 'none',
							},
							[mq.medium]: {
								display: 'block',
							},
							[mq.large]: {
								display: 'block',
							},
						}}
					>
						<NavLink to="/daycare">DAYCARE</NavLink>
						<NavLink to="/boarding">BOARDING</NavLink>
						<NavLink to="/training">TRAINING</NavLink>
						<NavLink to="/grooming">GROOMING</NavLink>
						<NavLink to="/party-center">PARTY CENTER</NavLink>
						<NavLink to="/contact-us">CONTACT US</NavLink>
					</div>
				</div>

				{/* Contact Us Small Screen */}
				<div
					css={{
						position: 'absolute',
						top: '1rem',
						right: '0.75rem',
						height: '70px',
						[mq.extraSmall]: {
							display: 'block',
						},
						[mq.small]: {
							display: 'block',
						},
						[mq.medium]: {
							display: 'none',
						},
						[mq.large]: {
							display: 'none',
						},
					}}
				>
					<NavContactButton href={`tel:${process.env.REACT_APP_PHONE}`}>
						CALL
					</NavContactButton>
					<NavContactButton
						css={{
							marginTop: '0.6rem',
						}}
						href={`mailto:${process.env.REACT_APP_EMAIL}`}
					>
						EMAIL
					</NavContactButton>
				</div>
			</div>
		</div>
	)
}

export {Navbar}
