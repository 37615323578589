/** @jsxImportSource @emotion/react */
import * as React from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {Routes, Navigate, Route, BrowserRouter} from 'react-router-dom'
import {Navbar} from './components/navigation'
import {Footer} from './components/footer'
import {DashboardScreen} from 'screens/dashboard'
import {DaycareScreen} from 'screens/daycare'
import {BoardingScreen} from 'screens/boarding'
import {TrainingScreen} from 'screens/training'
import {GroomingScreen} from 'screens/grooming'
import {ContactUsScreen} from 'screens/contact-us'
import {NewClientScreen} from 'screens/new-client'
import {PartyCenterScreen} from 'screens/party-center'

function App() {
	React.useEffect(() => {
		AOS.init({
			duration: 600,
			easing: 'ease',
			once: true,
		})
	}, [])

	return (
		<div
			className="App"
			css={{
				textAlign: 'center',
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh',
			}}
		>
			<div
				css={{
					flex: '1',
				}}
			>
				<BrowserRouter>
					<Navbar />
					<AppRoutes />
				</BrowserRouter>
			</div>
			<Footer />
		</div>
	)
}

function AppRoutes() {
	return (
		<Routes>
			<Route path="/" element={<DashboardScreen />} />
			<Route path="/daycare" element={<DaycareScreen />} />
			<Route path="/boarding" element={<BoardingScreen />} />
			<Route path="/training" element={<TrainingScreen />} />
			<Route path="/grooming" element={<GroomingScreen />} />
			<Route path="/party-center" element={<PartyCenterScreen />} />
			<Route path="/contact-us" element={<ContactUsScreen />} />
			<Route path="*" element={<Navigate to="/" />} />
			<Route path="/clients/new" element={<NewClientScreen />} />
		</Routes>
	)
}

export default App
