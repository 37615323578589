/** @jsxImportSource @emotion/react */
import * as React from 'react'
import * as mq from 'utils/media-queries'
import {
	Container,
	ClientButtonContainer,
	ClientHeader,
	ClientLink,
	ClientPhone,
	ClientPhoneContainer,
	ClientSection,
	Column,
	FAQQuestion,
	FAQTitle,
	InfoContentContainer,
	TitleContainer,
	TitleContent,
	TitleHeader,
	TitleImageContainer,
	TitleSection,
	InfoHeader,
	InfoContent,
	PricingCard,
	PricingTitle,
	PricingCircle,
	FlexRow,
	FlexColumn,
	Image,
	PricingLabel,
} from 'utils/lib'
import * as color from 'utils/colors'
import partyCenterBanner from 'assets/party-center-banner.jpg'
import partyCenterVector from 'assets/party-center-vector.jpg'
import partyCenter1 from 'assets/party-center-1.jpg'
import partyCenter2 from 'assets/party-center-2.jpg'
import partyCenter3 from 'assets/party-center-3.jpg'
import partyCenter4 from 'assets/party-center-4.jpg'

function PartyCenterTitle() {
	return (
		<TitleSection
			css={{
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
			}}
		>
			<TitleImageContainer
				src={partyCenterBanner}
				alt="party-center"
				left="-130%"
			/>
			<TitleContainer
				css={{
					[mq.extraSmall]: {
						width: '100%',
					},
				}}
			>
				<TitleHeader
					css={{
						[mq.extraSmall]: {
							fontSize: '3rem',
						},
					}}
				>
					Party Center
				</TitleHeader>
				<TitleContent>
					Accepting reservations for events such as birthday parties or other
					celebrations.
				</TitleContent>
			</TitleContainer>
		</TitleSection>
	)
}

function PartyCenterGeneralInformation() {
	return (
		<InfoContentContainer
			data-aos="fade-in"
			css={{
				marginTop: '4rem',
			}}
		>
			<InfoHeader>Party Center Facility</InfoHeader>
			<InfoContent>
				Guests can book this indoor facility 7 days a week including holidays
				and may drop off supplies prior to the event (we will store them for you
				up to two days in advance). Guests may take pictures and/or bring a
				photographer.
			</InfoContent>
			<InfoContent css={{marginTop: '1rem'}}>
				This facility (24x50) is equipped to handle between 1-20 guests. Please
				contact us if you have more than 20 guests. Booking time includes setup,
				party and breakdown time.
			</InfoContent>

			<InfoContent css={{marginTop: '2rem', marginBottom: '1rem'}}>
				We will provide:
			</InfoContent>

			<InfoContent>· Tables and chairs</InfoContent>
			<InfoContent>· Puppy ball pit, mini tunnels and ramps</InfoContent>
			<InfoContent>· Indoor potty station toilet</InfoContent>
			<InfoContent>· Filtered water bowls</InfoContent>
			<InfoContent>· Dog ice cream machine (additional $65)</InfoContent>
		</InfoContentContainer>
	)
}

function PartyCenterPricing() {
	return (
		<InfoContentContainer data-aos="fade-in">
			<FlexRow>
				<FlexColumn>
					<InfoHeader css={{marginTop: '2rem'}}>Pricing</InfoHeader>
					<InfoContent>
						<PricingCard
							css={{backgroundColor: color.pricingPartyRooomBackground}}
						>
							<PricingTitle>2 Hours</PricingTitle>
							<PricingCircle>275</PricingCircle>
							<PricingLabel>(Includes setup and breakdown time)</PricingLabel>
						</PricingCard>
					</InfoContent>
					<InfoContent>
						<PricingCard
							css={{backgroundColor: color.pricingPartyRooomBackground}}
						>
							<PricingTitle>3 Hours</PricingTitle>
							<PricingCircle>375</PricingCircle>
							<PricingLabel>(Includes setup and breakdown time)</PricingLabel>
						</PricingCard>
					</InfoContent>
					<InfoContent>
						<PricingCard
							css={{backgroundColor: color.pricingPartyRooomBackground}}
						>
							<PricingTitle>5 Hours</PricingTitle>
							<PricingCircle>475</PricingCircle>
							<PricingLabel>(Includes setup and breakdown time)</PricingLabel>
						</PricingCard>
					</InfoContent>
				</FlexColumn>
			</FlexRow>
			<InfoContent
				css={{
					textAlign: 'center',
					marginTop: '2rem',
				}}
			>
				<img
					css={{
						margin: '0 auto',
						maxWidth: '400px',
						[mq.extraSmall]: {
							maxWidth: '250px',
						},
						[mq.extraSmall]: {
							maxWidth: '250px',
						},
						[mq.medium]: {},
						[mq.large]: {
							marginTop: '4rem',
							marginBottom: '4rem',
						},
					}}
					src={partyCenterVector}
					alt="party-center-vector"
				/>
			</InfoContent>
		</InfoContentContainer>
	)
}

function PartyCenterGallery() {
	return (
		<InfoContentContainer>
			<FlexRow>
				<FlexColumn orientation="left">
					<Image data-aos="fade-in" src={partyCenter1} alt="birthday dog 1" />
				</FlexColumn>

				<FlexColumn orientation="right">
					<Image data-aos="fade-in" src={partyCenter2} alt="birthday dog 2" />
				</FlexColumn>
			</FlexRow>
			<FlexRow>
				<FlexColumn orientation="left">
					<Image data-aos="fade-in" src={partyCenter3} alt="birthday dog 3" />
				</FlexColumn>
				<FlexColumn orientation="right">
					<Image data-aos="fade-in" src={partyCenter4} alt="birthday dog 4" />
				</FlexColumn>
			</FlexRow>
		</InfoContentContainer>
	)
}

function PartyCenterFAQs() {
	return (
		<InfoContentContainer data-aos="fade-in">
			<Column>
				<FAQTitle />
				<FAQQuestion
					question={`Are there any requirements for guest attending special events?`}
					answer={`All guest must be social and up to date on vaccinations and free from any parasites.
          `}
				/>
				<FAQQuestion
					question={`Is the event space indoors and lighted at night?`}
					answer={`Yes the space is indoors with plenty of light and climate controlled.`}
				/>
				<FAQQuestion
					question={`What if my dogs needs to go potty?`}
					answer={`We have an indoor potty station with a dog flush toilet.`}
				/>
				<FAQQuestion
					question={`Will there be water for my dogs?`}
					answer={`Yes we have water bowls and filtered water for our four legged friends.`}
				/>
				<FAQQuestion
					question={`Can I bring drinks for my guests?`}
					answer={`Yes you can bring non-alcoholic drinks in non-breakable containers. Sorry no glass, breakable containers or serving items.`}
				/>
				<FAQQuestion
					question={`What I if want snacks or treats for my guest?`}
					answer={`You can bring safe treats for dogs and your guest. Sorry no foods that are toxic to dogs are allowed.`}
				/>
				<FAQQuestion
					question={`What if my dog's birthday cake needs to be kept cold or frozen?`}
					answer={`We have a refrigerator freezer to store you cake until served.`}
				/>
				<FAQQuestion
					question={`How many hours do I have to setup and break down?`}
					answer={`The time reserved is for setup, the party and break down. Normally setup takes the longest. We recommend viewing the space before hand to have an idea on how you want it setup.`}
				/>
				<FAQQuestion
					question={`If a guest needs to bring in a breakable or fragile gift?`}
					answer={`Please contact before hand. We will try and accommodate the request if possible.`}
				/>
			</Column>
		</InfoContentContainer>
	)
}

export function PartyCenterScreen() {
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Container>
			<PartyCenterTitle />
			<ClientSection>
				<ClientHeader>BOOK YOUR APPOINTMENT</ClientHeader>
				<ClientButtonContainer>
					<ClientLink to="/clients/new">New Client Application</ClientLink>
					<ClientLink to="https://pawpartner.com/pz/book?p=268457">
						Returning Client
					</ClientLink>
					<ClientPhoneContainer>
						<ClientPhone />
					</ClientPhoneContainer>
				</ClientButtonContainer>
			</ClientSection>

			<PartyCenterGeneralInformation />

			<PartyCenterPricing />

			<PartyCenterGallery />
			<PartyCenterFAQs />
		</Container>
	)
}
